import React from "react";
import { Helmet } from 'react-helmet'
import "./internacional/App.scss"
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import imgLogo from "./internacional/assets/img/logo-ucc.webp";
import imgThumbEco from "./internacional/assets/img/hero/ecologicas-thumb.webp";
import imgThumbMC from "./internacional/assets/img/hero/mercado-de-capitales-thumb.webp";
import imgThumbAg from "./internacional/assets/img/hero/agromanagement-thumb.webp";
import imgThumbGP from "./internacional/assets/img/hero/gestion-de-proyectos-thumb.webp";

const General = () => {

	return (
		<>
            <Helmet>
                <script>
                {`(function(w,d,s,l,i)
                    {w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});
                    var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-PLKH83F');`}
                </script>

                <noscript>
                {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PLKH83F"
                        height="0" width="0" style="display:none;visibility:hidden"> 
                    </iframe>`}
                </noscript>
            </Helmet>
			<Navbar sticky="top" bg="azul" className="navbar-landing">
				<Container
					style={{
						justifyContent: "start",
					}}
				>
					<Navbar.Brand>
						<img
							src={imgLogo}
							width="100%"
							height="100%"
							alt="Diplomatura en mercado de capitales"
						/>
					</Navbar.Brand>
				</Container>
			</Navbar>
			<main>
                <Container>
                    <Row className="align-content-center py-5 py-md-6">
                        <Col>
                            <h2 className="text-center fw-semibold mb-4">Nuestros Programas</h2>
                            <Row className="row-cols-1 row-cols-md-3 gy-4 gx-4">
                                <Col className="mb-4">
                                    <div className="card text-bg-dark border-0 position-relative overlay-azul shadow-sm">
                                    <img src={imgThumbEco} className="card-img" width="100%" height="100%" alt="Diplomado: eco lógicas proyectuales. Mecanismos de integración de tecnologías sustentables al proyecto." />
                                    <div className="card-img-overlay d-flex flex-column justify-content-between p-4">
                                        <a href="EcoLogicas" className="d-flex btn btn-outline-light btn-sm align-self-end stretched-link">Ver Programa</a>
                                        <h5 className="card-title fs-6 text-start fw-semibold text-white">Diplomado: eco lógicas proyectuales. Mecanismos de integración de tecnologías sustentables al proyecto.</h5>
                                    </div>
                                    </div>
                                </Col>
                                <Col className="mb-4">
                                    <div className="card text-bg-dark border-0 position-relative overlay-azul shadow-sm">
                                    <img src={imgThumbMC} className="card-img" width="100%" height="100%" alt="Diplomatura en mercado de capitales" />
                                    <div className="card-img-overlay d-flex flex-column justify-content-between p-4">
                                        <a href="MercadoCapitales" className="d-flex btn btn-outline-light btn-sm align-self-end stretched-link">Ver Programa</a>
                                        <h5 className="card-title fs-5 text-start fw-semibold text-white">Diplomatura en mercado de capitales</h5>
                                    </div>
                                    </div>
                                </Col>
                                <Col className="mb-4">
                                    <div className="card text-bg-dark border-0 position-relative overlay-azul shadow-sm">
                                    <img src={imgThumbAg} className="card-img" width="100%" height="100%" alt="Diplomatura en Agromanagement" />
                                    <div className="card-img-overlay d-flex flex-column justify-content-between p-4">
                                        <a href="Agromanagement" className="d-flex btn btn-outline-light btn-sm align-self-end stretched-link">Ver Programa</a>
                                        <h5 className="card-title fs-5 text-start fw-semibold text-white">Diplomatura en Agromanagement</h5>
                                    </div>
                                    </div>
                                </Col>
                                <Col className="mb-4">
                                    <div className="card text-bg-dark border-0 position-relative overlay-azul shadow-sm">
                                    <img src={imgThumbGP} className="card-img" width="100%" height="100%" alt="Gestión de proyectos" />
                                    <div className="card-img-overlay d-flex flex-column justify-content-between p-4">
                                        <a href="GestionDeProyectos" className="d-flex btn btn-outline-light btn-sm align-self-end stretched-link">Ver Programa</a>
                                        <h5 className="card-title fs-5 text-start fw-semibold text-white">Gestión de proyectos</h5>
                                    </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>

                </main>
            <footer className="bg-azul">
                <Container className="py-5">
                    <Row>
                        <Col className="text-center">
                            <h2>Certificación Oficial de la Universidad Católica de Córdoba</h2>
                        </Col>
                    </Row>

                </Container>

            </footer>
		</>
	);
};

export default General;
